@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap");

body {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: "Cairo", sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(359.16deg,
      rgba(21, 20, 29, 0.2) 0.69%,
      rgba(32, 27, 57, 0.2) 20.04%,
      rgba(70, 64, 94, 0.2) 41.67%,
      rgba(27, 20, 67, 0.2) 55.33%),
    #0e0d15 !important;
}

a {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  text-decoration: none !important;
}

a.hover-line:hover {
  text-decoration: underline;
}

img {
  border: 0;
  outline: 0;
  vertical-align: middle;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}

.btn-action-link {
  display: inline-block;
  background: rgba(255, 255, 255, 0.02);
  border: none;
  box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16),
    inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: #ffffff;
  padding: 22px 22px;
  letter-spacing: 0.4px;
  min-width: 210px;
  text-decoration: none;
}

.btn-action-link:hover {
  color: #ffffff;
}

.btn-action-link::before {
  position: absolute;
  content: "";
  height: 3px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(269.99deg,
      #eeeeee -69.29%,
      #fffee2 -62.4%,
      rgba(255, 255, 255, 0.950883) -57.4%,
      #df68fd -51.6%,
      rgba(255, 255, 255, 0.815523) -44.74%,
      #b7eee5 -35.82%,
      #20282e -12.7%,
      #29353c -5.34%,
      rgba(255, 255, 255, 0.596267) -1.97%,
      #c9fbfe 5.76%,
      #79a2f2 11.83%,
      rgba(255, 255, 255, 0.741036) 17.11%,
      #79a2f2 23.13%,
      #5d23fd 33.9%,
      #ffffff 45.48%,
      #51555e 79.22%,
      #699cff 89.6%,
      #ffffff 92.26%,
      #79a2f2 95.03%,
      #ffffff 97.45%);
}

.inner-wrap {
	padding: 18rem 0 0;
	background: url(../images/inner-bg.png) 0 top no-repeat;
	position: relative;
}

@media screen and (max-width: 767px) {
	.inner-wrap {
		padding: 5rem 1rem 0;
		padding-top: 12rem;
		max-width: 100vw;
	}
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}