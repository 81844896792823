@import './animation.scss';
@import "~bootstrap/scss/bootstrap";

.draw-container {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 9999;
	font-family: 'Cairo';

	background: #05051aee;
	color: white;

	overflow-y: auto;

	padding: 20px;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-height: 590px) {
		justify-content: flex-start;
		align-items: center;
	}

	&-ele {
		margin-top: 55px;
		width: 100%;

		ul {
			li {
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			}
		}
	}

	animation: drawer-animation 0.3s ease-in-out;

	.nav {
		list-style-type: none;
		flex-direction: column;
		opacity: 1;
		animation-name: nav-animation;
		animation-delay: 0s;
		animation-duration: 0.3s;
		animation-timing-function: ease-in-out;

		li {
			padding: 16px 0;
			text-align: center;
			font-weight: 600;
			font-size: 16px;
			line-height: 130%;
			/* identical to box height, or 21px */

			text-align: center;
			letter-spacing: 0.05em;
			text-transform: capitalize;

			&:active {
				background: transparent;
			}

			&:has(.drop-down) {
				position: relative;

				// width: 80px;
				&::after {
					content: '';
					position: absolute;
					top: 28px;
					left: calc(50% + 50px);
					background-color: rgba(255, 255, 255, 0.6);
					width: 12px;
					height: 1px;
					transform-origin: left top;
					transform: rotate(45deg);
				}

				&::before {
					content: '';
					position: absolute;
					top: 28px;
					left: calc(50% + 54px);

					background-color: rgba(255, 255, 255, 0.6);
					width: 12px;
					height: 1px;
					transform-origin: right top;
					transform: rotate(-45deg);
				}

				&.active::after {
					content: '';
					position: absolute;
					top: 28px;
					left: calc(50% + 58px);
					background-color: rgba(255, 255, 255, 0.6);
					width: 12px;
					height: 1px;
					transform-origin: left top;
					transform: rotate(45deg);
				}

				&.active::before {
					content: '';
					position: absolute;
					top: 28px;
					left: calc(50% + 45px);

					background-color: rgba(255, 255, 255, 0.6);
					width: 12px;
					height: 1px;
					transform-origin: right top;
					transform: rotate(-45deg);
				}

				&:hover {
					.drop-down-container {
						display: block;
					}
				}

				.drop-down-container {
					left: 10px;
					width: 90%;
					color: black;
					display: none;

					border-radius: 5px;
					transform-origin: top;
					animation: open-drop-down 0.2s linear;
					transition: all 0.2s linear 0.3s;

					ul {
						padding: 10px 20px;
						list-style-type: none;

						li {
							font-family: 'Cairo';
							text-align: left;
							font-weight: 600;
							font-size: 16px;
							line-height: 130%;
							letter-spacing: 0.05em;
							color: #FFFFFF;
							cursor: pointer;
							width: 100%;
							padding-bottom: 16px;
							border-bottom: 1px solid rgba(255, 255, 255, 0.2);

							&:hover {
								color: rgba(255, 255, 255, 0.6);
							}

							&:last-child {
								border-bottom: none;
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}

		$menu_items: 8;

		@for $i from 0 to $menu_items {
			.item-show-animation-#{$i} {
				animation: item-animation-#{$i} 1s ease-in-out;
			}
		}

		@for $i from 0 to $menu_items {
			.item-close-animation-#{$i} {
				animation: item-close-animation-#{$i} 1s ease-in-out;
			}
		}
	}

	.close-container {
		position: absolute;
		right: 40px;
		top: 40px;
		width: 30px;
		height: 30px;
		cursor: pointer;

		.line-1 {
			position: absolute;
			top: 14px;
			left: 0;
			width: 30px;
			height: 2px;
			background: white;
			transform: rotate(135deg);

			// animation: line1-animation 0.8s ease;
		}

		.line-2 {
			position: absolute;
			top: 14px;
			left: 0;
			width: 30px;
			height: 2px;
			transform: rotate(45deg);
			background: white;
			// animation: line2-animation 0.8s ease;
		}

		.line1-show-animation {
			animation: line1-animation 0.8s ease;
		}

		.line2-show-animation {
			animation: line2-animation 0.8s ease;
		}

		.line1-close-animation {
			animation: line1-close-animation 0.8s ease;
		}

		.line2-close-animation {
			animation: line2-close-animation 0.8s ease;
		}
	}

	.header-footer {
		margin-top: 67px;
		display: flex;
		justify-content: center;
		align-items: center;

		.btn-theme {
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(0, 0, 0, 0.1);
			border: 1px solid rgba(255, 255, 255, 0.05);
			box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
			backdrop-filter: blur(8px);
			border-radius: 10px;
			padding: 7px 8px;

			img {
				width: 16px;
				height: 18px;
			}

			&>div {
				margin-left: 10px;
				width: 26px;
				height: 26px;

				background-color: #23272E;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}