@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap');

button {
  cursor: pointer;
}
body {
  color: #4e5665;
  font-size: 14px;
  font-family: 'Cairo', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(
      359.16deg,
      rgba(21, 20, 29, 0.2) 0.69%,
      rgba(32, 27, 57, 0.2) 20.04%,
      rgba(70, 64, 94, 0.2) 41.67%,
      rgba(27, 20, 67, 0.2) 55.33%
    ),
    #0e0d15 !important;
}
a {
  color: #4991c4;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  text-decoration: none !important;
}
a.hover-line:hover {
  text-decoration: underline;
}
img {
  border: 0;
  outline: 0;
  vertical-align: middle;
  display: inline !important;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}
p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}

.btn-action-link {
  display: inline-block;
  background: rgba(255, 255, 255, 0.02);
  border: none;
  box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16),
    inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: #ffffff;
  padding: 22px 22px;
  letter-spacing: 0.4px;
  min-width: 210px;
  text-decoration: none;
}
.btn-action-link:hover {
  color: #ffffff;
}
.btn-action-link::before {
  position: absolute;
  content: '';
  height: 3px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(
    269.99deg,
    #eeeeee -69.29%,
    #fffee2 -62.4%,
    rgba(255, 255, 255, 0.950883) -57.4%,
    #df68fd -51.6%,
    rgba(255, 255, 255, 0.815523) -44.74%,
    #b7eee5 -35.82%,
    #20282e -12.7%,
    #29353c -5.34%,
    rgba(255, 255, 255, 0.596267) -1.97%,
    #c9fbfe 5.76%,
    #79a2f2 11.83%,
    rgba(255, 255, 255, 0.741036) 17.11%,
    #79a2f2 23.13%,
    #5d23fd 33.9%,
    #ffffff 45.48%,
    #51555e 79.22%,
    #699cff 89.6%,
    #ffffff 92.26%,
    #79a2f2 95.03%,
    #ffffff 97.45%
  );
}

.heading-row {
  text-align: center;
}
.headingbox {
  display: inline-block;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.1) 98.47%
  );
  backdrop-filter: blur(16px);
  border-radius: 4px;
  min-width: 340px;
  padding: 0 40px;
  margin: 0 auto;
  position: relative;
}
.headingbox::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: -8px;
  background: url(../images/bottom-line.png) center 0 no-repeat;
  width: 100%;
  height: 20px;
}
.headingbox h2 {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
  margin: 0;
  padding: 8px 0 15px;
}

/* Home Wrapper*/
.header-bottom-row {
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
}
.header-bottom-right {
  background: url(../images/rectanglebg.png) 0 0 no-repeat;
  width: 428px;
  height: 45px;
  position: relative;
}
.header-bottom-right::before {
  position: absolute;
  content: '';
  left: -3px;
  top: 0;
  background: url(../images/rectangle-devider.png) 0 0 no-repeat;
  width: 33px;
  height: 45px;
  z-index: -1;
}
.header-bottom-right ul {
  padding: 0;
  margin: 0;
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
}
.header-bottom-right ul li {
  display: flex;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #ffffff;
  position: relative;
}
.header-bottom-right ul li::before {
  position: absolute;
  content: '';
  left: 6px;
  top: 0;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.header-bottom-right ul li:first-child::before {
  display: none;
}
.header-bottom-right ul li img {
  margin-right: 10px;
}
.home-wrapperbg {
  background-image: url(../images/home-wrapperbg.png);
  background-size: cover;
  background-position: center bottom;
  height: 1638px;
  padding-top: 30px;
}
.header-wrap {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  z-index: 2;
}
.header-nav {
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-nav::before {
  position: absolute;
  content: '';
  left: 0px;
  top: 8px;
  bottom: 8px;
  width: 25px;
  background: url(../images/navafterbg.png) 0 0 no-repeat;
  z-index: -1;
  border-radius: 79px;
}
.header-navbar {
  display: flex;
  align-items: center;
}
.header-logo {
  padding: 0 55px;
}
.submit-col {
  margin-right: 25px;
}
.header-logo img {
  height: 65px;
}
.headermenu ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.headermenu ul li {
  position: relative;
}
.headermenu ul li a::before {
  position: absolute;
  content: '';
  left: 0;
  top: -7px;
  bottom: 0;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(12deg);
  height: 298px;
}
.headermenu ul li a {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.6);
  padding: 55px 38px 55px 65px;
  position: relative;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
}
.dropdown-menu.header-dropdown {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.85);
  padding: 0;
}
.dropdown-menu.header-dropdown a {
  padding: 15px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.dropdown-menu.header-dropdown a::before,
.dropdown-menu.header-dropdown a::after {
  display: none;
}

/* New Style 12.02.2022 */

.inner-wrap {
  padding: 16rem 0 0;
  background: url(../images/inner-bg.png) 0 top no-repeat;
  position: relative;
}

.pool-step-wrap {
  position: relative;
}
.pool-step-wrap::after {
  position: absolute;
  content: '';
  left: 10%;
  right: 10%;
  margin: 0 auto;
  top: 24px;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  height: 10px;
}
.pool-step-wrap ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.pool-step-wrap ul li {
  width: 20%;
  text-align: center;
  position: relative;
  z-index: 1;
}
.pool-step-wrap ul li.active .pool-step-round {
  background: url(../images/round-box.png) 0 0 no-repeat;
  background-size: 52px 52px;
  width: 52px;
  height: 52px;
}
.pool-step-round {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border-radius: 100%;
  background: url(../images/register-stepbg.png) 0 0 no-repeat;
  background-size: 48px 48px;
  font-size: 20px;
  line-height: 48px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.pool-step-name {
  display: block;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-top: 12px;
}
.pool-step-container {
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-column-gap: 30px;
  margin: 2rem 0;
}
.pool-step-contentbox {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
  position: relative;
}
.pool-step-content h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}
.pool-step-content h3 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  margin-bottom: 1rem;
}
.step-form-control {
  margin-bottom: 2rem;
}
.step-form-control label {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 0.5rem;
}
.step-grid-pool {
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  padding-top: 3rem;
}
.poll-select-box {
  padding: 0 15px 2rem;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  text-align: center;
}
.poll-select-img {
  background: url(../images/round-bg.png) 0 0 no-repeat;
  background-size: 65px 65px;
  width: 75px;
  height: 75px;
  margin: -35px auto 0;
}
.poll-select-img1 {
  background: url(../images/selectmark.png) 0 0 no-repeat;
  background-size: 65px 65px;
  width: 75px;
  height: 75px;
  margin: -35px auto 0;
}
.poll-select-name {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}
.poll-name-display {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #fff;
  margin-left: 1rem;
}
.poll-select-info {
  display: block;
  margin: 0 auto;
  line-height: 13px;
  line-height: 20px;
  font-weight: 300;
  color: #fff;
  letter-spacing: 0.6px;
  max-width: 320px;
}
.swap-top-grid {
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr auto 1fr auto;
}
.swap-cion {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  color: #fff;
  padding: 0 1rem;
  margin-top: 2.5rem;
}

.raised-amount {
  display: block;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
}
.submit-form-control {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border: none;
  padding: 12px 15px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}
.submit-form-control:focus {
  outline: none;
}
select.submit-form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../images/down-arrow.png) no-repeat 90% center rgba(0, 0, 0, 0.3);
}
.roya-text {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: #fff;
  padding: 0 0 0 1rem;
  margin-top: 2.5rem;
}
.set-dates-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 15px;
}
.set-date-info-box {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}
.info-icon-box {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #eeeeee 0deg,
    #fffee2 14.87deg,
    rgba(255, 255, 255, 0.950883) 25.67deg,
    #df68fd 38.19deg,
    rgba(255, 255, 255, 0.815523) 53deg,
    #b7eee5 72.26deg,
    #20282e 122.18deg,
    #29353c 138.07deg,
    rgba(255, 255, 255, 0.596267) 145.34deg,
    #c9fbfe 162.04deg,
    #79a2f2 175.13deg,
    rgba(255, 255, 255, 0.741036) 186.54deg,
    #79a2f2 199.54deg,
    #5d23fd 222.78deg,
    #ffffff 247.79deg,
    #51555e 320.65deg,
    #699cff 343.05deg,
    #ffffff 348.79deg,
    #79a2f2 354.77deg,
    #ffffff 360deg
  );
}
.set-date-text {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  font-weight: 300;
  color: #ffffff;
}
.allocation-grid01 {
  display: grid;
  grid-column-gap: 45px;
  grid-template-columns: 1fr auto;
}
.allocation-grid02 {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
}
.access-label {
  display: block;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 0.5rem;
}
.ratio-control {
  position: relative;
}
.ratio-label {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.45);
}
.access-grid {
  margin-bottom: 2rem;
}
.access-col {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.access-switch {
  margin: 0 25px;
}
.type-label {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: rgba(255, 255, 255, 0.65);
}

.switch {
  position: relative;
  display: inline-block;
  width: 68px;
  height: 40px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 35px;
  width: 35px;
  left: 2px;
  bottom: 2px;
  background: url(../images/radio-check.png) 0 0 no-repeat;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* footer */
/* footer */

.footerwrap {
  background-image: url(../images/footerbg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 272px 0 0;
}
.footercontainer {
  text-align: center;
}
.footerlogo {
  margin: 40px 0 30px;
}
.footercontainer p {
  font-size: 22px;
  line-height: 1.2;
  color: #a0a5ad;
  margin-bottom: 3rem;
}
.social-link {
  display: flex;
  padding: 0;
  margin: 0;
}
.social-link li + li {
  margin-left: 15px;
}
.social-link li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: url(../images/social-roundbg.png) 0 0 no-repeat;
  background-size: 60px 60px;
  margin: 0 auto;
}
.social-link li a svg {
  position: relative;
  z-index: 1;
  width: 25px;
  height: 25px;
}
.footer-social-link {
  padding: 3rem 0 5rem;
}
.footer-social-link ul li {
  text-align: center;
}
.footer-social-link ul {
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.footer-social-link ul li {
  width: 100px;
  margin: 0;
}
.social-link-info {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
}

/* Pool Name Steps */
.pool-name-row {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 350px;
}
.pool-name-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
}
.pool-name-box h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}
.pool-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.pool-steps-select {
  position: relative;
}
.pool-steps-select input {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}
.pool-steps-select label {
  position: relative;
  padding-left: 65px;
  font-size: 16px;
  line-height: 45px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.45px;
}
.pool-steps-select label::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background-image: url(../images/register-stepbg.png);
  background-size: 45px 45px;
  width: 45px;
  height: 45px;
}
.pool-steps-select input[type='radio']:checked + label:before {
  background-image: url(../images/selectmark.png);
}
.poll-steps-right {
  display: flex;
  align-items: center;
}
.poll-steps-right .btn-action-link {
  min-width: 180px;
}
.pool-confirming-info {
  display: inline-block;
  margin-right: 2rem;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.45px;
}
.btn-action-link {
  display: inline-block;
  background: rgba(255, 255, 255, 0.02);
  border: none;
  box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16),
    inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: #ffffff;
  padding: 22px 22px;
  letter-spacing: 0.4px;
  min-width: 210px;
  text-decoration: none;
}
.btn-action-link:hover {
  color: #ffffff;
}
.btn-action-link::before {
  position: absolute;
  content: '';
  height: 3px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(
    269.99deg,
    #eeeeee -69.29%,
    #fffee2 -62.4%,
    rgba(255, 255, 255, 0.950883) -57.4%,
    #df68fd -51.6%,
    rgba(255, 255, 255, 0.815523) -44.74%,
    #b7eee5 -35.82%,
    #20282e -12.7%,
    #29353c -5.34%,
    rgba(255, 255, 255, 0.596267) -1.97%,
    #c9fbfe 5.76%,
    #79a2f2 11.83%,
    rgba(255, 255, 255, 0.741036) 17.11%,
    #79a2f2 23.13%,
    #5d23fd 33.9%,
    #ffffff 45.48%,
    #51555e 79.22%,
    #699cff 89.6%,
    #ffffff 92.26%,
    #79a2f2 95.03%,
    #ffffff 97.45%
  );
}
.bottom-border-none::before {
  display: none;
}
.pool-steps-action {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.pool-right-col {
}
.pool-preview-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
}
.pool-preview-box h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}
.pool-step-view {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.step-view-heading {
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: rgba(255, 255, 255, 0.5);
}
.step-view-info {
  display: block;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: #fff;
  margin-top: 8px;
}
.pool-step-devider {
  margin: 1.5rem auto;
  max-width: 80%;
  border-top: 1px solid #4f5258;
}
.pool-date-view {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem 2rem 1rem;
  margin-top: 2rem;
}
.pool-date-col {
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  grid-column-gap: 10px;
  margin-bottom: 1rem;
}
.pool-date-info {
  font-size: 13px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.45px;
}
.pool-date-label {
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.45px;
}
.pool-select-nameview {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.pool-nameview-display {
  display: block;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: #ffffff;
  margin-bottom: 2px;
}
.pool-name-colview {
  margin-left: 20px;
}
.pool-nameview-selected {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.45px;
  color: rgba(255, 255, 255, 0.5);
}
/* Admin */
.admin-wrap-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.admin-wrap-heading h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin: 0;
}
.admin-table-box {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
}
.admin-table-list {
  display: grid;
  grid-template-columns: 7rem 1.25fr 3fr 15rem;
  grid-column-gap: 10px;
}
.admin-table-header {
  padding-bottom: 1rem;
  border-bottom: 1px solid #4f5258;
  margin-bottom: 1rem;
}
.admin-heading-col {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.45px;
}
.admin-list-col {
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.45px;
}
.admin-table-item {
  padding: 1.5rem 0;
}
.admin-table-item .access-col {
  margin-top: 0;
}
/* Whitelist wrap */

.whitelist-wrap {
  background: linear-gradient(
    223.88deg,
    rgba(255, 255, 255, 0) 1.79%,
    rgba(255, 255, 255, 0.15) 98.47%
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
}

.whitelist-wrap h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #4f5258;
}
.whitelist-top-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 15px;
}
.whitelist-status-timelock {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 50px;
  margin-bottom: 2.5rem;
}
.whitelist-status-timelock .type-label {
  color: #fff;
}
.whitelist-set-time h3 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  margin-bottom: 2rem;
}
.whitelist-action-row {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.btn-action-link1 {
  display: inline-block;
  background: rgba(255, 255, 255, 0.02);
  border: none;
  box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16),
    inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  color: #ffffff;
  padding: 22px 22px;
  letter-spacing: 0.4px;
  min-width: 210px;
  text-decoration: none;
}
.btn-action-link1:hover {
  color: #ffffff;
}
.btn-action-link1::before {
  position: absolute;
  content: '';
  height: 3px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(
    269.99deg,
    #eeeeee -69.29%,
    #fffee2 -62.4%,
    rgba(255, 255, 255, 0.950883) -57.4%,
    #df68fd -51.6%,
    rgba(255, 255, 255, 0.815523) -44.74%,
    #b7eee5 -35.82%,
    #20282e -12.7%,
    #29353c -5.34%,
    rgba(255, 255, 255, 0.596267) -1.97%,
    #c9fbfe 5.76%,
    #79a2f2 11.83%,
    rgba(255, 255, 255, 0.741036) 17.11%,
    #79a2f2 23.13%,
    #5d23fd 33.9%,
    #ffffff 45.48%,
    #51555e 79.22%,
    #699cff 89.6%,
    #ffffff 92.26%,
    #79a2f2 95.03%,
    #ffffff 97.45%
  );
}

.pool-selection-img {
  height: -20px;
}
.pool-select-img {
  /* background-size: 50px 50px;
    margin-top: -20px;
    margin-left: 160px; */
  background-size: 75px 75px;
  width: 75px;
  height: 75px;
  margin: -35px auto 0;
}

.header-bottom-right1 {
  background: url(../images/rectanglebg.png) 0 0 no-repeat;
  width: 550px;
  height: 45px;
  position: relative;
  background-size: cover;
}
.header-bottom-right1::before {
  position: absolute;
  content: '';
  left: -3px;
  top: 0;
  background: url(../images/rectangle-devider.png) 0 0 no-repeat;
  width: 33px;
  height: 45px;
  z-index: -1;
}
.header-bottom-right1 ul {
  padding: 0;
  margin: 0;
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
}
.header-bottom-right1 ul li {
  display: flex;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #ffffff;
  position: relative;
}
.header-bottom-right1 ul li::before {
  position: absolute;
  content: '';
  left: 6px;
  top: 0;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.header-bottom-right1 ul li:first-child::before {
  display: none;
}
.header-bottom-right1 ul li img {
  margin-right: 10px;
}
