$color_1: #fff;
$color_2: #ffffff;
$color_3: white;
$color_4: #5b5875;
$color_5: #74e1ed;
$color_6: rgba(255, 255, 255, 0.6);
$color_7: #a0a5ad;
$color_8: #9aa1ac;
$color_9: rgba(255, 255, 255, 0.5);
$color_10: #3ebb52;
$color_11: rgba(255, 255, 255, 0.65);
$color_12: rgba(255, 255, 255, 0.3);
$color_13: rgba(255, 255, 255, 0.2);
$color_14: rgba(255, 255, 255, 1);
$color_15: rgba(255, 255, 255, 0.25);
$color_16: black;
$color_17: red;
$color_18: hsla(0, 0%, 100%, 0.5);
$color_19: gray;
$font-family_1: "Work Sans", sans-serif;
$font-family_2: Cairo;
$font-family_3: "Cairo";
$font-family_4: "cairo";
$background-color_1: rgba(0, 0, 0, 0.5);
$background-color_2: #009bff;
$background-color_3: transparent;
$background-color_4: white;
$background-color_5: gray;
$background-color_6: #2196f3;
$background-color_7: #f9f9f9;
$background-color_8: #f1f1f1;
$background-color_9: #671ae4;
$border-color_1: #23272e;
$border-color_2: #4f5258;

.header-wrap {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  z-index: 2;
}

.header-nav {
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16),
    inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before {
    position: absolute;
    content: "";
    left: 0px;
    top: 8px;
    bottom: 8px;
    width: 25px;
    background: url(../images/navafterbg.png) 0 0 no-repeat;
    z-index: -1;
    border-radius: 79px;
  }
}

.header-navbar {
  display: flex;
  align-items: center;
}

.header-logo {
  padding: 0 38px;

  img {
    height: 65px;
  }
}

.submit-col {
  margin-right: 25px;
}

.headermenu {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;

    li {
      position: relative;

      a {
        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: -7px;
          bottom: 0;
          width: 1px;
          background: rgba(255, 255, 255, 0.2);
          color: $color_3;
          transform: rotate(12deg);
          height: 298px;
        }

        display: block;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: $color_3;
        padding: 55px 38px 55px 65px;
        position: relative;
        z-index: 1;
        text-decoration: none;
        overflow: hidden;
      }

      a.active {
        color: $color_1;
      }
    }
  }
}

.dropdown-menu.header-dropdown {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.85);
  padding: 0;

  a {
    padding: 15px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

.header-wrap-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 2;

  .full-width {
    width: 100%;
    text-align: center;
  }

  .mobile-logo {
    width: 90px;
    height: 55px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .royale-amount {
    width: 147px;
    margin-left: 40px;
    background: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16),
      inset 0px 0px 2px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;

    .notranslate {
      max-width: 90px;
      width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }

    .dropdown-icon {
      margin-left: 12px;
    }
    .disconnect-container {
      position: absolute;
      top: 40px;
      right: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      border-radius: 10px;
      padding-block: 30px;
      padding-inline: 10px;
      border: 1px solid #ffffff48;
      label {
        cursor: pointer;
		color: #ffffff;
      }
    }
  }

  .hamburger-menu {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    background: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16),
      inset 0px 0px 2px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 12px;

    img {
      width: 18px;
      height: 15px;
    }
  }

  .menu-icon-hide {
    animation: menuicon-hide 0.3s ease-in-out;
    opacity: 0;
  }

  .menu-icon-show {
    animation: menuicon-show 0.3s ease-in-out;
    opacity: 1;
  }
}

@media screen and (max-width: 991px) {
  .header-wrap {
    display: none;
  }

  .header-wrap-mobile {
    display: flex;
  }

  .header-nav {
    display: none;
  }

  .comparisonbox {
    display: none;
  }

  .header-bottom-right {
    display: none;
  }

  .headingbox {
    min-width: auto;
    padding: 0 30px;

    h2 {
      font-size: 17px;
    }
  }
}
